<template>
  <div>
    <div class="disbox">
      <!-- <recommend-title :Title="illness.illness" :color="'#F6A609'" class="rectitle"></recommend-title> -->
      <el-row>
        <el-col class="asd">
          <div class="bg">
            <div class="rectitle">
              {{ illness.illness }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="3">
          <!-- <div v-for="(item,index) in options" class="btn" :class="{actived: index === currentIndex}" @click="itemClick(index)" :key="index">{{item}}</div> -->
          <div
            class="btn"
            @click="itemClick(0, '')"
            :class="{ actived: 0 === currentIndex }"
          >
            首页
          </div>
          <div
            class="btn"
            @click="itemClick(1, 'video')"
            :class="{ actived: 1 === currentIndex }"
            v-if="videoList.length > 0"
          >
            相关视频
          </div>
          <div
            class="btn"
            @click="itemClick(2, 'audio')"
            :class="{ actived: 2 === currentIndex }"
            v-if="audioList.length > 0"
          >
            相关语音
          </div>
          <div
            class="btn"
            @click="itemClick(3, 'article')"
            :class="{ actived: 3 === currentIndex }"
            v-if="articleList.length > 0"
          >
            相关文章
          </div>
          <div
            class="btn"
            @click="itemClick(4, 'qa')"
            :class="{ actived: 4 === currentIndex }"
            v-if="qaList.length > 0"
          >
            相关问答
          </div>
        </el-col>
        <el-col :span="21" style="position: relative;">
          <div class="dcontainer">
            <div class="shouye" v-if="currentIndex == 0">
              <div class="guide" ref="zhinan">
                <el-row>
                  <el-col :span="24" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">就诊指南</div>
                    </div>
                  </el-col>
                  <el-row>
                    <el-col :span="2" class="gray">疾病简介：</el-col>
                    <el-col :span="22" class="wenan">
                      <span v-html="illness.description"></span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2" class="gray">就诊科室：</el-col>
                    <el-col :span="22">
                      <span v-html="illness.cureDemp"></span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2" class="gray">常见病因：</el-col>
                    <el-col :span="22">
                      <span v-html="illness.reason"></span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2" class="gray">常见症状：</el-col>
                    <el-col :span="22">
                      <span v-html="illness.symptom"></span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="2" class="gray">传染性：</el-col>
                    <el-col :span="22">
                      <span
                        v-html="
                          illness.infectivity ? illness.infectivity : '无'
                        "
                      ></span>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
              <div class="detail">
                <el-row v-if="videoList.length > 0">
                  <el-col :span="20" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">相关视频</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="more">
                    <!-- 11111111111111111111111111111111 -->
                    <a @click="tomore(1, 'video')" v-if="videoList.length > 7"
                      >查看更多></a
                    >
                  </el-col>
                  <el-row>
                    <el-col
                      v-for="project in videoList"
                      :key="project.pid"
                      :offset="1"
                      :span="6"
                    >
                      <videos-list-itemtest
                        :videos-item="project"
                        :type="'video'"
                        class="now"
                      ></videos-list-itemtest>
                    </el-col>
                  </el-row>
                </el-row>
                <el-row v-if="audioList.length > 0">
                  <el-col :span="20" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">相关音频</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="more">
                    <a @click="tomore(2, 'audio')" v-if="audioList.length > 7"
                      >查看更多></a
                    >
                  </el-col>
                  <el-row>
                    <el-col
                      v-for="project in audioList"
                      :key="project.pid"
                      :offset="1"
                      :span="6"
                    >
                      <videos-list-itemtest
                        :type="'voice'"
                        :videos-item="project"
                        class="now"
                      ></videos-list-itemtest>
                    </el-col>
                  </el-row>
                </el-row>
                <el-row v-if="articleList.length > 0">
                  <el-col :span="20" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">相关文章</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="more">
                    <a @click="tomore(3, 'article')" v-if="audioList.length > 7"
                      >查看更多></a
                    >
                  </el-col>
                  <el-row>
                    <el-col
                      :span="6"
                      v-for="project in articleList"
                      :key="project.pid"
                      :offset="1"
                    >
                      <videos-list-itemtest
                        :type="'article'"
                        :videos-item="project"
                        class="now"
                      ></videos-list-itemtest>
                    </el-col>
                  </el-row>
                </el-row>
                <el-row v-if="qaList.length > 0">
                  <el-col :span="20" class="guideTitle">
                    <div>
                      <img src="~assets/dian.png" alt="" />
                    </div>
                    <div>
                      <div class="zn">相关问答</div>
                    </div>
                  </el-col>
                  <el-col :span="4" class="more">
                    <a @click="tomore(4, 'qa')" v-if="qaList.length >= 3"
                      >查看更多></a
                    >
                  </el-col>
                  <el-row>
                    <el-col
                      :span="24"
                      v-for="project in qaList"
                      :key="project.pid"
                      :offset="1"
                    >
                      <long-question-answertest
                        :question-item="project"
                        :type="'ts'"
                        class="now"
                      ></long-question-answertest>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
            </div>
            <div v-if="currentIndex !== 0" class="xq" ref="xq">
              <el-row class="row" v-if="currentIndex === 4">
                <el-col
                  :span="24"
                  v-for="project in xqdata"
                  :key="project.pid"
                  :offset="1"
                >
                  <long-question-answertest
                    :question-item="project"
                    :type="'ts'"
                    class="now ql"
                  ></long-question-answertest>
                </el-col>
              </el-row>
              <el-row class="row" v-if="currentIndex != 4">
                <el-col
                  :span="6"
                  v-for="project in xqdata"
                  :key="project.pid"
                  :offset="1"
                >
                  <videos-list-itemtest
                    :videos-item="project"
                    class="now"
                  ></videos-list-itemtest>
                </el-col>
              </el-row>
              <!-- <el-pagination
        v-if="xqdata.length > 0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="100"
        :current-page="currentPage"
        layout="prev, pager, next, jumper"
        :total="1000">
    </el-pagination> -->
            </div>
            <!-- <question-answer-list :question="question" class="qw" v-if="currentIndex === 4"></question-answer-list> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <bottom-bar v-if="currentIndex === 0" class="bottom"></bottom-bar>
  </div>
</template>

<script>
// import VideosList from 'components/content/videos/VideosList'
// import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// import OptionsListItem from 'components/content/optionslist/OptionsListItem'
import BottomBar from 'components/content/bottombar/BottomBar'
import LongQuestionAnswertest
  from 'components/content/questionanswer/LongQuestionAnswertest'
import VideosListItemtest from 'components/content/videos/VideosListItemtest'
import {
  getdisdetailList,
  getdistagdetailList
} from 'network/getData'

export default {
  name: 'DiseaseDeatil',
  components: {
    // RecommendTitle,
    // OptionsListItem,
    // VideosList,
    LongQuestionAnswertest,
    VideosListItemtest,
    BottomBar
  },
  data () {
    return {
      disName: '',
      currentPage: 1,
      currentIndex: 0,
      now: 0,
      nowpage: 2,
      xqdata: [],
      type: '',
      isMoreLoad: true,
      definePageNum: 2,
      audioList: [
      ],
      articleList: [
      ],
      illness: [
      ],
      qaList: [
      ],
      videoList: [
      ],
      options: [
        '首页',
        '相关视频',
        '相关语音',
        '相关文章',
        '相关问答'
      ],
      istomore: false
    }
  },
  created () {
    // if (this.$route.query.index) {
    //   // this.currentIndex = Number(this.$route.query.index)
    //   this.now = Number(this.$route.query.index)
    //   this.currentIndex = Number(this.$route.query.index)
    // }
  },
  methods: {
    itemClick (index, e) {
      if (index !== 0) {
        this.istomore = true
      } else {
        this.istomore = false
      }
      this.definePageNum = 2
      this.currentIndex = index
      this.type = e
      this.currentPage = 1
      this.nowpage = 2
      this.getdistagdetailListdata(16, 1, e, this.disName)
    },
    nowindex (val) {
      this.currentIndex = val
    },
    tomore (index, e) {
      this.currentIndex = index
      this.istomore = true
      this.getdistagdetailListdata(16, 1, e, this.disName)
    },
    async getdisdetailListtdata (rows, page, doctorId, infoType) {
      try {
        const result = await getdisdetailList(rows, page, doctorId, infoType)
        // this.audioList = result.data.data.audioList
        // this.articleList = result.data.data.articleList
        this.illness = result.data.data.illness
        // this.qaList = result.data.data.qaList
        // this.videoList = result.data.data.videoList
        this.disName = this.illness.illness
        // console.log(result.data.data)
      } catch (error) {
        // console.log(error)
      }
    },
    async getdistagdetailListdata (rows, page, type, infoTag) {
      try {
        const result = await getdistagdetailList(rows, page, type, infoTag)
        // this.xqdata = result.data.data
        // console.log(result.data.data)
        if (type === 'audio') {
          this.audioList = result.data.data
        } else if (type === 'article') {
          this.articleList = result.data.data
        } else if (type === 'video') {
          this.videoList = result.data.data
        } else if (type === 'qa') {
          this.qaList = result.data.data
        }
        if (this.istomore) {
          this.xqdata = result.data.data
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getdistagdetailListdatamore (rows, page, type, infoTag) {
      try {
        const result = await getdistagdetailList(rows, page, type, infoTag)
        if (result.data.message === 'OK') {
          this.isMoreLoad = true
        }
        // this.xqdata = result.data.data
        const newArr = [...this.xqdata, ...result.data.data]
        this.xqdata = newArr
        // console.log(result.data.data)
      } catch (error) {
        // console.log(error)
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.currentPage = val
      // this.getDoctorInfoListdata(this.keshichange[this.currentIndex], 15, this.currentPage, 'doctor')
      this.getdistagdetailListdata(16, this.currentPage, this.type, this.disName)
    },
    scrollLoadMore () {
      this.isMoreLoad = false
      // 防止多次加载
      this.getdistagdetailListdatamore(8, this.definePageNum, this.type, this.disName)
    }
    // async getMediaInfoListdata (filterCondition, row, page, type) {
    //   try {
    //     // 获取轮播图数据列表
    //     const result = await getMediaInfoList(filterCondition, row, page, type)
    //     if (type === 'video' && row === 8) {
    //       this.docaudios = result.data.data
    //     }
    //     if (type === 'video' && row === 5) {
    //       this.videos = result.data.data
    //     }
    //     if (type === 'audio') {
    //       this.docvideos = result.data.data
    //     }
    //     if (type === 'article') {
    //       const list = result.data.data
    //       const half = Math.ceil(list.length / 2)
    //       this.articleList = list.splice(0, half)
    //       this.articleList2 = list.splice(-half)
    //       // console.log('this.articleList', this.articleList, this.articleList2)
    //     }
    //   } catch (error) {
    //     // console.log(error)
    //   }
    // }
    // handleScroll () {
    //   const scrollTop = this.$refs.xq.scrollTop
    //   const clientHeight = this.$refs.xq.clientHeight
    //   const scrollHeight = this.$refs.xq.scrollHeight
    //   if (scrollTop + clientHeight + 50 >= scrollHeight) {
    //     this.nowpage++
    //     this.getdistagdetailListdatamore(8, this.nowpage, this.type, this.disName)
    //   }
    // }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  async mounted () {
    await this.getdisdetailListtdata(Number(this.$route.query.i), null)
    await this.getdistagdetailListdata(8, 1, 'video', this.disName)
    await this.getdistagdetailListdata(8, 1, 'audio', this.disName)
    await this.getdistagdetailListdata(8, 1, 'article', this.disName)
    await this.getdistagdetailListdata(8, 1, 'qa', this.disName)
    // window.addEventListener('scroll', jieliu(this.handleScroll, 1000))
    const _this = this
    window.addEventListener('scroll', function () {
      const scr = document.documentElement.scrollTop || document.body.scrollTop // 向上滚动的那一部分高度
      const clientHeight = document.documentElement.clientHeight // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      const scrHeight = document.documentElement.scrollHeight || document.body.scrollHeight // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 10 >= scrHeight && _this.istomore) {
        if (_this.isMoreLoad) { // this.isMoreLoad控制滚动是否加载更多
          _this.definePageNum += 1 // 加载更多是definePageNum+ 1
          _this.scrollLoadMore()
        } else {
          return null
        }
      }
      // console.log(scr / scrHeight, '11', scrHeight)
      // if (scr / scrHeight >= 0.08 && !_this.istomore) {
      //   _this.getdistagdetailListdata(8, 1, 'audio', _this.disName)
      // }
      // if (scr / scrHeight >= 0.2 && !this.istomore) {
      //   _this.getdistagdetailListdata(8, 1, 'article', _this.disName)
      //   _this.getdistagdetailListdata(8, 1, 'qa', _this.disName)
      // }
    })
  },
  destroyed () {
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped>
.disbox{
  /* position: absolute; */
  width: 1200px;
  /* height: 631px; */
  /* left: 120px; */
  /* margin-left: 120px; */
  top: 130px;
  margin: 0 auto;
}
.title{
  /* position: relative; */
}
.rectitle{
  font-size: 36px;
  line-height: 54px;
  /* width: 300px; */
  height: 100px;
  text-align: center;
  position: relative;
  top: -35px;
  /* float: left; */
  /* margin-left: 50%;
  transform: translateX(-50%); */
}
.bg{
  position: absolute;
  /* width: inherit; */
  height: 14px;
  /* margin: 0 -78px; */
  margin-top: 50px;
  padding: 0 50px;
  /* opacity: 0.4; */
  transform: translateX(-50%);
  left: 50%;
  background-color: rgba(246,166,9,0.4);
}
.llist{
position: absolute;
width: 112px;
height: 230px;
left: 20px;
top: 123px;
}
.dcontainer{
  position: relative;
  width: 1008px;
  /* height: 452px;
  left: 192px;
  top: 122px; */
  background: #FFFFFF;
}

/* .yyvideo{
  position: absolute;
  margin-top:-120px;
} */
.now{
  position: relative;
}
/* .el-col{
  width: 222px;
} */
.span{
  width: 222px;
}
.el-col-offset-1{
  margin-left: 2%;
  margin-bottom: 1%;
}
.detail .el-col-6 :hover,
.detail .el-col-24 :hover,
.xq .el-col-6 :hover,
.xq .el-col-24 :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.detail .now :hover,
.xq .now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
}
.row{
  position: relative;
  /* top: 50px; */
}
.bottom{
  margin-top: 160px;
}
.guideTitle{
  color: #262626;
  display: flex;
  align-items: center;
  margin: 18px 0px;
}
.guideTitle .zn {
  margin-left: 10px;
  font-size: 20px;
}
/* .guideTitle :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
} */
.gray{
  color: #8C8C8C;
}
.guide .el-row{
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
}
.wenan{
  color: #262626;
}
.more{
  /* margin: 0 auto; */
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  width: 75px;
  height: 22px;
  left: 903px;
  top: 10px;
  z-index: 99; */
  color:#8C8C8C;
}
.more:hover{
  color: #000000;
}
.btn{
  display: flex;
flex-direction: row;
align-items: flex-start;
padding: 4px 28px;
/* position: static; */
/* width: 84px; */
height: 30px;
/* left: 0px;
top: 50px; */
background: #F5F5F5;
border-radius: 4px;
flex: none;
justify-content: center;
align-items: center;
/* order: 1;
flex-grow: 0; */
margin: 20px 0px;
cursor: pointer;
}
.actived{
background: #4263EB;
color: #FFFFFF;
}
.el-pagination {
    top: 50px;
    margin-top: 40px;
    position: relative;
}
.ql{
  width: 1038px;
}
.detail .el-col-24{
  width: 1038px;
}
.detail{
  position: relative;
}
.detail .el-row{
  margin-top: 30px;
}
.dian{
  width: 8px;
  height: 8px;
}
.dian img{
  width: 100%;
  height: 100%;
}
.el-col-6 {
  width: 22%;
}
.more a{
  cursor: pointer;
}
</style>
